/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useLayoutEffect, useState } from 'react';
import {
  capitalize, findIndex, get, sortBy, uniq,
} from 'lodash';


import cls from 'lib-frontend-shared/src/helpers/cls';

import Checkbox from 'lib-frontend-shared/src/components/Checkbox';
import Grid from 'lib-frontend-shared/src/components/Grid';
import Linear from 'lib-frontend-shared/src/components/Linear';
import Spacer from 'lib-frontend-shared/src/components/Spacer';
import Switch from 'lib-frontend-shared/src/components/Switch';
import Typography from 'lib-frontend-shared/src/components/Typography';
import moment from '../helpers/moment';
import TimeRangePicker from './TimeRangePicker';
import UnifiedLabel, { defaultAnnotationProps } from './UnifiedLabel';

import { weekDays } from '../enums';

import './Schedule.css';
import { DateTimePicker } from './date-time-picker/DateTimePicker';

const fromToVariants = (generate) => ['startTime', 'endTime'].reduce(
  (acc, type) => ({ ...acc, [type]: generate(type) }),
  {},
);

const endOfDay = '00:00';
const startOfDay = '00:00';

const defaultTime = { from: startOfDay, to: endOfDay };

const timeFormat = 'HH:mm';
const parseTime = (time) => moment.utc(`0001-01-01T${time}:00.000Z`);

const defaultStartAndEndTime = { startTime: startOfDay, endTime: endOfDay };

const Schedule = ({
  disabled,

  legacySchedule,
  date,
  days = [],
  time: extTime,

  selectPeriod = legacySchedule && Boolean(date),

  onChange,
  value,
}) => {
  const time = extTime || {};
  const schedule = value || {};
  const { workingDays = [] } = schedule;
  const allowTimeSelection = !legacySchedule || Boolean(extTime);
  const period = (legacySchedule ? date : schedule.period) || {};
  const periodField = legacySchedule ? 'date' : 'period';

  const [allDays, setAllDays] = useState(true);

  const update = (delta) => onChange({
    ...(legacySchedule ? { date, days, time } : schedule),
    ...delta,
  });

  useLayoutEffect(() => {
    const noDays = days.length === 0;
    if (!days) update({ time: defaultTime });
    setAllDays(legacySchedule ? noDays : !workingDays.length);
  }, [days]);
  // Should remove this control after doing the schedule change for all entities.
  const dayControls = weekDays.map((day) => {
    const selected = days.includes(day);
    return (
      <>
        <label className={cls('Schedule-label', { disabled })}>
          <Checkbox
            checked={selected || !days.length}
            color="secondary"
            disabled={disabled || allDays}
            key={day}
            onChange={() => update({
              days: selected ? days.filter((item) => item !== day) : uniq([...days, day]),
            })}
            shape="circle"
          />
          <Typography disabled={disabled || allDays || !selected} variant="para.sm">
            {capitalize(day)}
          </Typography>
        </label>
        {allowTimeSelection ? (
          <TimeRangePicker
            disabled={disabled || allDays || !selected}
            noInternalRange
            onChange={{
              from: (updatedFrom) => {
                const from = moment(updatedFrom).format(timeFormat);
                const to = time.to || parseTime(from).add('1', 'hour').format(timeFormat);
                update({ time: { from, to } });
              },
              to: (updatedTo) => {
                const to = moment(updatedTo).format(timeFormat);
                const from = time.from || parseTime(to).subtract('1', 'hour').format(timeFormat);
                update({ time: { from, to } });
              },
            }}
            value={fromToVariants((type) => moment(time[type], 'HH:mm').toISOString())}
            variant="compact"
          />
        ) : (
          <div />
        )}
      </>
    );
  });

  const dayWithDateControls = weekDays.map((day) => {
    const { dayOfWeek, workingHours = [] } = workingDays.find(({ dayOfWeek: workingDay }) => workingDay === day) || {};
    const { startTime, endTime } = get(workingHours, '0', {});
    const selected = dayOfWeek === day;
    return (
      <>
        <label className={cls('Schedule-label', { disabled })}>
          <Checkbox
            checked={selected || !workingDays.length}
            color="secondary"
            disabled={disabled || allDays}
            key={day}
            onChange={() => {
              if (selected) {
                update({
                  workingDays: workingDays.filter((workingDay) => workingDay.dayOfWeek !== day),
                });
              } else {
                update({
                  workingDays: sortBy(
                    [
                      ...workingDays,
                      { dayOfWeek: day, workingHours: [defaultStartAndEndTime] },
                    ],
                    (workingDay) => findIndex(weekDays, workingDay.dayOfWeek),
                  ),
                });
              }
            }}
            shape="circle"
          />
          <Typography disabled={disabled || allDays || !selected} variant="para.sm">
            {capitalize(day)}
          </Typography>
        </label>
        <TimeRangePicker
          disabled={disabled || allDays || !selected}
          noInternalRange
          onChange={{
            from: (updatedFrom) => {
              const updatedStartTime = moment(updatedFrom).format(timeFormat);
              const updatedEndTime = endTime || parseTime(updatedStartTime).add('1', 'hour').format(timeFormat);
              update({
                workingDays: workingDays.map((workingDay) => (
                  workingDay.dayOfWeek === day ? {
                    ...workingDay,
                    workingHours: [{ startTime: updatedStartTime, endTime: updatedEndTime }],
                  } : workingDay
                )),
              });
            },
            to: (updatedTo) => {
              const updatedEndTime = moment(updatedTo).format(timeFormat);
              const updatedStartTime = startTime || parseTime(updatedEndTime).subtract('1', 'hour').format(timeFormat);
              update({
                workingDays: workingDays.map((workingDay) => (
                  workingDay.dayOfWeek === day ? {
                    ...workingDay,
                    workingHours: [{ startTime: updatedStartTime, endTime: updatedEndTime }],
                  } : workingDay
                )),
              });
            },
          }}
          value={{
            from: moment(startTime, 'HH:mm').toISOString(),
            to: moment(endTime, 'HH:mm').toISOString(),
          }}
          variant="compact"
        />
      </>
    );
  });

  const dateApplied = selectPeriod && (period.from || period.to);

  const defaultDaysWithDate = weekDays.map((day) => ({ dayOfWeek: day, workingHours: [{ startTime: startOfDay, endTime: endOfDay }] }));
  return (
    <Linear orientation="vertical" width="100Pr">
      <Spacer y="sm" />
      <UnifiedLabel label="Any day, any time" {...defaultAnnotationProps.toggle}>
        <Switch
          checked={allDays}
          color="secondary"
          disabled={disabled}
          onChange={({ target: { checked } }) => update(
            legacySchedule
              ? { days: checked ? [] : weekDays, time: defaultTime }
              : { workingDays: checked ? [] : defaultDaysWithDate },
          )}
        />
      </UnifiedLabel>
      <Spacer y="lg" />
      <Grid align="center" cols="auto 1fr" gap="lg">
        {legacySchedule ? dayControls : dayWithDateControls}
      </Grid>
      {selectPeriod && (
        <>
          <Spacer y="lg" />
          <UnifiedLabel label="Apply to a Period" {...defaultAnnotationProps.toggle}>
            <Switch
              checked={dateApplied}
              color="secondary"
              disabled={disabled}
              onChange={({ target: { checked } }) => {
                const defaultStart = moment().add('1', 'hour');
                update({
                  [periodField]: checked ? {
                    from: defaultStart.toISOString(),
                    to: defaultStart.clone().add('1', 'day').toISOString(),
                  } : {
                    from: null,
                    to: null,
                  },
                });
              }}
            />
          </UnifiedLabel>
          <Spacer y="lg" />
          <Grid width="100Pr" cols="repeat(auto-fit, minmax(auto, 260px))" colGap="md">
            <DateTimePicker
              disabled={disabled || !dateApplied}
              disablePast
              label="From Date"
              onChange={(start) => {
                if (!start) {
                  onChange({ [periodField]: { from: null } });
                  return;
                }
                const momentFrom = moment.max(moment(), moment(start));
                const recomputedScheduledTo = momentFrom.isSameOrAfter(period.to)
                  ? momentFrom.clone().add(1, 'day')
                  : moment(period.to);
                update({
                  [periodField]: {
                    from: momentFrom.toISOString(),
                    to: recomputedScheduledTo.toISOString(),
                  },
                });
              }}
              value={moment(period.from)}
              variant="compact"
            />
            <DateTimePicker
              disabled={disabled || !dateApplied}
              disablePast
              label="To Date"
              onChange={(end) => {
                if (!end) {
                  onChange({ [periodField]: { to: null } });
                  return;
                }
                const momentFrom = moment(date.from);
                const momentTo = moment.max(moment(), moment(end));
                const recomputedScheduledTo = momentFrom.isSameOrAfter(momentTo)
                  ? momentFrom.clone().add(1, 'day')
                  : momentTo;
                update({
                  [periodField]: {
                    from: momentFrom.toISOString(),
                    to: recomputedScheduledTo.toISOString(),
                  },
                });
              }}
              value={moment(period.to)}
              variant="compact"
            />
          </Grid>
        </>
      )}
    </Linear>
  );
};

export default Schedule;

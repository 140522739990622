/* eslint-disable no-nested-ternary */
import {
  chunk, get, intersection,
} from 'lodash';
import {
  carriyoClient,

  // utils
  nodeMiddlewareClient,
  nodeMiddlewareIOClient,
  timeZoneOffset,
  toMerchantsParam,
} from './base';
import { getStates } from '../store';
import {
  fromLocation,
  toLocation,
} from '../api-transforms';


export const getLocations = async ({
  page,
  pageSize,
  searchString,
  status,
  allFilters = {},
  sortBy,
  sortDirection,
  allMerchants = false,
  fields = [],
  exportCSV,
  maxResults,
}, signal) => {
  const { global: { selectedMerchantIds }, auth: { locations, countries } } = getStates();
  const explicitCountryFilter = get(allFilters, 'country', []);
  const countryAccess = explicitCountryFilter.length ? intersection(countries, explicitCountryFilter) : countries;
  const {
    merchants = [],
    ...restAllFilters
  } = allFilters;
  const updatedFilters = {
    ...restAllFilters,
    location_id: locations,
    country: countryAccess,
  };

  const url = `/locations${exportCSV ? '-export' : ''}`;
  const client = exportCSV ? nodeMiddlewareIOClient : nodeMiddlewareClient;
  const { data } = await client.get(url, {
    signal,
    params: {
      ...(page !== undefined ? { page } : {}),
      ...(pageSize !== undefined ? { pageSize } : {}),
      ...toMerchantsParam(allMerchants ? undefined : (merchants.length ? merchants : selectedMerchantIds)),
      ...(status ? { status } : {}),
      ...(searchString ? { searchString } : {}),
      ...(sortBy ? { sortBy } : {}),
      ...((sortBy && sortDirection) ? { sortDirection: sortDirection.toUpperCase() } : {}),
      ...(fields.length ? { fields } : {}),
      ...updatedFilters,
      ...(maxResults ? { maxResults } : { }),
      tzoffset: timeZoneOffset,
    },
  });
  if (exportCSV) {
    return data;
  }
  const {
    pagination: locationPagination = {},
    items = [],
  } = data || {};
  return {
    totalLocations: get(locationPagination, 'total'),
    locations: items.map(fromLocation),
  };
};


export const getLocationDetails = async ({
  locationIds = [],
  locationNames = [],
  locationCodes = [],
}) => {
  const locationsToFetch = locationIds.length ? locationIds : (locationNames.length ? locationNames : locationCodes);
  const fieldProps = locationIds.length ? 'location_id' : (locationNames.length ? 'location_name' : 'location_code');

  const allRequestedLocations = await Promise.all(chunk(locationsToFetch, 20).map((identifiers) => nodeMiddlewareClient.get('/frontend/locations', {
    params: {
      [fieldProps]: identifiers,
      tzoffset: timeZoneOffset,
    },
  })));
  return {
    locations: allRequestedLocations.flatMap(({ data }) => get(data, 'items', [])).map(fromLocation),
  };
};

export const getLocationsByTerm = async (payload) => {
  const { data = [] } = await nodeMiddlewareClient.post('/get-locations-by-term', payload);
  return {
    locations: data.map(fromLocation),
  };
};

export const getLocation = async (locationId) => {
  const { data } = await carriyoClient.get(`/locations/${locationId}`);
  return fromLocation(data);
};

export const convertLocationsToCsv = async (locationData) => {
  const { data } = await nodeMiddlewareIOClient.post('/locations/upload/csv', locationData);
  return data;
};

export const upsertLocation = async (body) => {
  const { locationId } = body;
  const location = toLocation(body);
  if (locationId) {
    const { data } = await carriyoClient.put(`/locations/${locationId}`, location);
    return { data: fromLocation(data) };
  }
  const { data } = await carriyoClient.post('/locations', location);
  return { data: fromLocation(data) };
};

export const deleteLocation = ({ locationId }) => carriyoClient
  .delete(`/locations/${locationId}`, { data: {} });

export const importBulkLocations = async (request) => {
  const { data } = await carriyoClient.post('/locations/bulk/import', request);
  return data;
};

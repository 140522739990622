import React from 'react';
import { InputLabel } from '@material-ui/core';
import Linear from 'lib-frontend-shared/src/components/Linear';
import Typography from 'lib-frontend-shared/src/components/Typography';
import TimeField from './TimeField';

const TimeRangePicker = (props) => {
  const {
    id,
    label,
    disabled = false,
    disablePast,
    required = false,
    value = {
      from: undefined,
      to: undefined,
    },
    onChange = {
      from: () => {},
      to: () => {},
    },
    style = { width: '100%' },
    noInternalRange = false,
    range = {
      min: undefined,
      max: undefined,
    },
    variant,
  } = props;

  const [error, setError] = React.useState({
    from: false,
    to: false,
  });
  const [focus, setFocus] = React.useState({
    from: false,
    to: false,
  });

  const onError = (field, update) => setError({
    ...error,
    [field]: update,
  });

  const onFocus = (field, update) => setFocus({
    ...focus,
    [field]: update,
  });

  const genTimeField = (field, minDate, maxDate) => (
    <TimeField
      id={`${id || ''}-TimeRangePicker-${field}`}
      disabled={disabled}
      disablePast={disablePast}
      required={required}
      value={value[field]}
      onChange={onChange[field]}
      onFocus={(update) => onFocus(field, update)}
      onError={(update) => onError(field, update)}
      range={{
        min: minDate,
        max: maxDate,
      }}
      variant={variant}
    />
  );

  const divider = <div style={{ width: '8px' }} />;

  return (
    <div style={style}>
      <InputLabel
        disabled={disabled}
        required={required}
        focused={Object.keys(focus).some((key) => focus[key])}
        error={Object.keys(error).some((key) => error[key])}
        shrink
      >
        {label}
      </InputLabel>
      <Linear align="center" orientation="horizontal" width="100pr">
        <Typography variant="para.sm:body">
          {variant === 'compact' ? 'from' : 'From'}
        </Typography>
        {divider}
        {genTimeField(
          'from',
          range.min,
          noInternalRange ? undefined : value.to,
        )}
        {divider}
        <Typography variant="para.sm:body">to</Typography>
        {divider}
        {genTimeField(
          'to',
          noInternalRange ? undefined : value.from,
          range.max,
        )}
      </Linear>
    </div>
  );
};

export default TimeRangePicker;

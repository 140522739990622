import { useEffect } from 'react';
import useDeepMemo from 'lib-frontend-shared/src/helpers/useDeepMemo';
import { useGlobalStates } from '../store';
import { fetchCitiesForCountry } from '../actions/global';

/**
 * @param {string[]} countries
 */
export default function useCities(rawCountries) {
  const countries = useDeepMemo(rawCountries);
  const {
    auth: { authorizing },
    cities: citiesByCountry = {},
    states: statesByCountry = {},
    citiesByState: citiesByStateByCountry = {},
  } = useGlobalStates(['auth', 'cities', 'states', 'citiesByState']);

  useEffect(() => {
    if (authorizing) { return; }
    const countriesToFetchCitiesFor = countries.filter(
      (country) => !citiesByCountry[country],
    );

    if (countriesToFetchCitiesFor.length) {
      countriesToFetchCitiesFor.forEach(fetchCitiesForCountry);
    }
  }, [authorizing, countries, citiesByCountry]);

  return {
    cities: citiesByCountry,
    states: statesByCountry,
    citiesByState: citiesByStateByCountry,
  };
}

/* eslint-disable camelcase */
import {
  camelCase,
  find,
  map,
  startCase,
  snakeCase,
  sortBy,
  uniqBy,
  intersectionBy,
  get,
} from 'lodash';
import naturalSort from 'lib-frontend-shared/src/helpers/naturalSort';
import countryData from 'lib-frontend-shared/src/enums/countries.json';
import localeNameLookup from 'lib-frontend-shared/src/enums/locales.json';

import config from './config';

export { localeNameLookup };

const valueAny = '_ANY';
export const isValueAny = (value) => value === valueAny;
export const isOptionWithValueAny = ({ value }) => isValueAny(value);
export const makeOptionWithValueAny = (label) => ({ label, value: valueAny });

export const currencies = uniqBy(
  countryData
    .filter(({ currency: { code } }) => code)
    .map(({ currency: { code, name } }) => ({
      label: `${code} - ${name}`, value: code,
    }))
    .sort(naturalSort.by('label')),
  'label',
);

export const currencyByCountry = Object.fromEntries(
  countryData.map(({ code, currency }) => [code, currency.code]),
);

export const rtlLanguagesList = ['ar', 'he', 'fa', 'ku', 'ur'];

export const locales = sortBy(
  Object
    .entries(localeNameLookup)
    .map(([value, label]) => ({ value, label })),
  ['label'],
);

export const gccCountryCodes = ['AE', 'SA', 'KW', 'OM', 'BH', 'QA'];
const countriesWithMandatoryState = ['CA', 'US'];
export const isStateMandatory = (country) => countriesWithMandatoryState.includes(country);
const isDevOrTestEnv = ['DEV', 'TEST'].includes(config.env);
export const maxSelectedMerchants = isDevOrTestEnv ? 10 : 50;
export const maxUnSelectedMerchants = isDevOrTestEnv ? 10 : 50;

export const weekDays = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];

export const countries = countryData
  .map(({ code, name }) => ({ label: name.long, value: code }));

export const countryInfo = countryData.map(({
  code, flag, name, phone,
}) => ({
  code,
  flag,
  name: name.long,
  phonePrefix: `+${phone.prefix}`,
  phoneSample: phone.sample,
}));

export const SHARED_MERCHANT_ID = 'ACCOUNT';
export const CLICKNSHIP_TENANT_ID = 'CLICKNSHIP';

export const countryListWithPhonePrefixLabel = countryData.map(({ code, name, phone }) => ({
  label: `${name.long} (+${phone.prefix})`, value: code,
}));

export const countryToTimezoneLookup = Object.fromEntries(
  countryData.map(({ code, timezone }) => [code, timezone]),
);

const status = [{
  label: 'Booked',
  value: 'booked',
}, {
  label: 'Ready to Ship',
  value: 'ready_to_ship',
}, {
  label: 'Shipped',
  value: 'shipped',
}, {
  label: 'In Transit',
  value: 'in_transit',
}, {
  label: 'Out for Delivery',
  value: 'out_for_delivery',
}, {
  label: 'Awaiting Customer Collection',
  value: 'awaiting_customer_collection',
}, {
  label: 'Delivered',
  value: 'delivered',
}, {
  label: 'Delivery Confirmed',
  value: 'delivery_confirmed',
}, {
  label: 'Failed Collection Attempt',
  value: 'failed_collection_attempt',
}, {
  label: 'Failed Delivery Attempt',
  value: 'failed_delivery_attempt',
}, {
  label: 'Ready for Return',
  value: 'ready_for_return',
}, {
  label: 'Return in Transit',
  value: 'return_in_transit',
}, {
  label: 'Returned',
  value: 'returned',
}, {
  label: 'Return Confirmed',
  value: 'return_confirmed',
}, {
  label: 'Cancelled',
  value: 'cancelled',
}, {
  label: 'Cancelled by Carrier',
  value: 'cancelled_by_carrier',
}, {
  label: 'Suspended',
  value: 'suspended',
}, {
  label: 'Missing',
  value: 'missing',
}, {
  label: 'Delayed',
  value: 'delayed',
}, {
  label: 'Error',
  value: 'error',
}, {
  label: 'Pending',
  value: 'pending',
}, {
  label: 'Draft',
  value: 'draft',
}];

export const errorTypes = [{
  value: 'TECHNICAL',
  label: 'Technical',
}, {
  value: 'VALIDATION',
  label: 'Validation',
}, {
  value: 'UNKNOWN',
  label: 'Unknown',
}];

export const errorSources = [{
  value: 'CARRIYO',
  label: 'Carriyo',
}, {
  value: 'CARRIER',
  label: 'Carrier',
}];

export const errorCodes = [
  'error_response',
  'required_data_missing',
  'required_data_invalid',
  'no_carrier_assigned',
  'payment_method_invalid',
  'coordinates_invalid',
  'coordinates_latitude_invalid',
  'coordinates_longitude_invalid',
  'label_format_invalid',
  'weight_unit_invalid',
  'dimension_unit_invalid',
  'country_invalid',
  'email_invalid',
  'phone_number_invalid',
  'carrier_account_invalid',
  'invalid_input_carrier',
  'partner_location_invalid',
  'time_slot_invalid',
  'custom_attribute_invalid',
  'custom_attribute_value_invalid',
  'custom_attribute_value_max_length_exceeded',
  'parcel_item_invalid',
  'email_missing',
  'merchant_missing',
  'default_merchant_not_configured',
  'payment_currency_invalid',
  'postcode_missing',
  'item_origin_country_missing',
  'item_hscode_missing',
  'item_weight_missing',
  'parcel_info_missing',
  'parcel_weight_missing',
  'parcel_dimensions_missing',
  'timeout',
  'unexpected_response',
  'prebooked_tracking_error',
].sort().map((code) => ({
  value: code,
  label: startCase(code),
}));

export const errorTriggers = [{
  value: 'BOOKING',
  label: 'Booking',
}, {
  value: 'SCHEDULING',
  label: 'Scheduling',
}];

export const statuses = status;
export const reverseShipmentStatuses = status;

export const Status = {
  deleted: 'DELETED',
  active: 'ACTIVE',
  inactive: 'INACTIVE',
  pending: 'PENDING',
  trouble: 'TROUBLE',
};

const manifestStatus = [{
  label: 'Draft',
  value: 'draft',
}, {
  label: 'Pending',
  value: 'pending',
}, {
  label: 'Error',
  value: 'error',
}, {
  label: 'Ready to Ship',
  value: 'ready_to_ship',
}, {
  label: 'Shipped',
  value: 'shipped',
}, {
  label: 'Cancelled',
  value: 'cancelled',
}];

export const manifestStatuses = manifestStatus;

const returnRequestStatus = [{
  label: 'Pending',
  value: 'pending',
  notify: true,
  milestone: false,
}, {
  label: 'Approved',
  value: 'approved',
  notify: true,
}, {
  label: 'Completed',
  value: 'completed',
  notify: true,
}, {
  label: 'Rejected',
  value: 'rejected',
  notify: true,
}, {
  label: 'Cancelled',
  value: 'cancelled',
  notify: true,
  milestone: false,
}];
export const returnRequestStatuses = returnRequestStatus;
export const returnRequestMilestones = returnRequestStatus.filter(
  ({ milestone = true }) => milestone,
);

export const shipmentMilestones = [{
  label: 'Booked',
  value: 'booked',
}, {
  label: 'Shipped',
  value: 'shipped',
}, {
  label: 'Delivered',
  value: 'delivered',
}, {
  label: 'Returned',
  value: 'returned',
}];

const payment_mode = [{
  label: 'Pre Paid',
  value: 'PRE_PAID',
}, {
  label: 'Cash on Delivery',
  value: 'CASH_ON_DELIVERY',
}];
export const paymentModes = payment_mode;

export const webhookTypes = [{
  label: 'Shipment',
  value: 'SHIPMENT',
}, {
  label: 'Return Requests',
  value: 'RETURN_REQUEST',
}];

export const sourceTypes = [{
  label: 'Carriyo API',
  value: 'api',
}, {
  label: 'Carriyo Dashboard',
  value: 'dashboard',
}, {
  label: 'Carriyo Store App',
  value: 'storeapp',
}, {
  label: 'Shopify App',
  value: 'shopify_app',
}, {
  label: 'Shopify Connector',
  value: 'shopify_connector',
}, {
  label: 'WooCommerce Connector',
  value: 'woocommerce_connector',
}, {
  label: 'Magento Connector',
  value: 'magento_connector',
}];

const lookup = {
  // aliases
  countries,
  currencies,
  statuses,
  status: statuses, // alias
  reverseShipmentStatus: reverseShipmentStatuses, // alias
  returnRequestStatus,
  manifestStatus,
  paymentModes,
  paymentMode: paymentModes, // alias
  shipmentMilestones,
  milestones: shipmentMilestones, // alias
  locales,
  webhookTypes,
  errorTypes,
  errorSources,
  errorCodes,
  errorTriggers,
  sourceTypes,
};

export const getLabelFromValue = ({ enumName, value }) => {
  const { label = value } = find(
    lookup[snakeCase(enumName)] || lookup[camelCase(enumName)],
    ['value', value],
  ) || {};
  return label;
};
const adminRole = 'admin';
const accountManagerRole = 'account-manager';
const logisticsManagerRole = 'alshaya-logistics-manager';

const customAccountLevelRole = [{
  label: 'Alshaya Logistics Manager',
  value: logisticsManagerRole,
  description: 'This role has access to all system features for all Merchants except system settings. ',
},
{
  label: 'Alshaya CS Manager',
  value: 'alshaya-cs-manager',
  description: 'This role has access to all system features for some Merchants except system settings. this role is only relevant when using a mult-merchant environment.',
}];

const ITManagerRole = 'it-manager';
export const roles = [{
  label: 'Admin',
  value: adminRole,
  description: 'The admin role has access to all system features including system settings.',
}, {
  label: 'Account Manager',
  value: accountManagerRole,
  description: 'This role has access to all system features for all Merchants except system settings. ',
},
{
  label: 'Manager',
  value: 'manager',
  description: 'This role has access to all system features for some Merchants except system settings. this role is only relevant when using a mult-merchant environment.',
}, {
  label: 'Operator',
  value: 'operator',
  description: 'An operator can only view and manage shipments and inspect reports. But cannot manage Automation Rules, Service Levels and other manager level features.',
}, {
  label: 'IT Manager',
  value: ITManagerRole,
  description: 'Manages system logs, Retrigger webhooks, and Integration settings. Designed for IT oversight.',
}, {
  label: 'Viewer',
  value: 'viewer',
  description: 'The viewer can only view shipment information but cannot edit or manage shipments.',
}];

const ownerRole = 'owner';
const superAdminRole = 'super-admin';

export const ownerOrAboves = [{
  label: 'Super Admin',
  value: superAdminRole,
  description: 'This role is exclusively reserved for Carriyo support users.',
}, {
  label: 'Account Owner',
  value: ownerRole,
  description: 'The account owner has access to all features and also has access to the account management dashboard.',
}];

const adminOrAboves = [
  ...roles.filter(({ value }) => value === adminRole),
  ...ownerOrAboves,
];

export const allRoles = (tenantId) => {
  const customRoles = (
    get(config, `customUserRoles.${(tenantId || '').toUpperCase()}`)
    || get(config, 'customUserRoles._ANY')
    || []
  );
  const customRoleDetails = intersectionBy(customAccountLevelRole, customRoles.map((role) => ({ value: role })), 'value');
  const commulativeRoles = [
    ...roles.slice(0, 2),
    ...customRoleDetails,
    ...roles.slice(2),
  ];
  return [...ownerOrAboves, ...commulativeRoles];
};

export const storeappRoles = [{
  label: 'Super Admin',
  value: superAdminRole,
  description: 'This role is exclusively reserved for Carriyo support users.',
}, {
  label: 'Admin',
  value: adminRole,
  description: 'The admin role has access to system features, create operator users, and manage deletions.',
}, {
  label: 'Operator',
  value: 'operator',
  description: 'An operator can only view and manage the store.',
}];

export const mustRoleAccessAllMerchants = Array.prototype.includes.bind([
  adminRole, accountManagerRole, logisticsManagerRole, ...map(ownerOrAboves, 'value'),
]);

export const isAccountOwner = (role) => role === ownerRole;
export const isSuperAdmin = (role) => role === superAdminRole;
export const isITManager = (role) => role === ITManagerRole;
export const isAdminOrAbove = (role) => adminOrAboves.some(
  ({ value }) => role === value,
);
export const isOwnerOrAbove = (role) => ownerOrAboves.some(
  ({ value }) => role === value,
);

const shortNameByCountry = Object.fromEntries(
  countryData.map(({ code, name }) => [code, name.short]),
);

export const getShortNameForCountry = (code) => shortNameByCountry[code];

const codeByCountryName = Object.fromEntries(
  countryData.map(({ code, name }) => [name.long, code]),
);

export const getCodeFromCountryName = (name) => codeByCountryName[name];

export const serviceLevelOptions = [{
  label: 'Processing', value: 'FULFILLMENT',
}, {
  label: 'Collection', value: 'SHIPPING',
}, {
  label: 'Delivery', value: 'DELIVERY',
}, {
  label: 'Promised', value: 'PROMISED',
}];

export const alertCategories = [{
  field: 'emailDigestSubscribed',
  label: 'Receive alerts via email',
  isDisabled: ({ isInvalid }) => isInvalid,
  tooltip: 'User Email must be valid. You can add email from profile update.',
  categories: [],
}, {
  field: 'alertsCategoryBookingErrors',
  label: 'Booking Errors',
  categories: ['BOOKING_ERROR'],
}, {
  field: 'alertsCategoryFailedDeliveryAttempts',
  label: 'Failed Delivery',
  categories: ['FAILED_DELIVERY_ATTEMPT'],
}, {
  field: 'alertsCategoryLateShipments',
  label: 'Late Shipments',
  categories: ['BREACHED_CUSTOMER_PROMISE'],
}, {
  field: 'alertsCategoryShipmentsAtRisk',
  label: 'SLA Breach',
  categories: ['BREACHED_DELIVERY_SLA', 'BREACHED_COLLECTION_SLA', 'BREACHED_PROCESSING_SLA'],
}];

export const httpTextByStatus = {
  100: 'Continue',
  101: 'Switching Protocols',
  102: 'Processing',
  103: 'Early Hints',
  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  203: 'Non-Authoritative Information',
  204: 'No Content',
  205: 'Reset Content',
  206: 'Partial Content',
  207: 'Multi-Status',
  208: 'Already Reported',
  226: 'IM Used',
  300: 'Multiple Choices',
  301: 'Moved Permanently',
  302: 'Found',
  303: 'See Other',
  304: 'Not Modified',
  307: 'Temporary Redirect',
  308: 'Permanent Redirect',
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Payload Too Large',
  414: 'URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Range Not Satisfiable',
  417: 'Expectation Failed',
  421: 'Misdirected Request',
  422: 'Unprocessable Content',
  423: 'Locked',
  424: 'Failed Dependency',
  425: 'Too Early',
  426: 'Upgrade Required',
  428: 'Precondition Required',
  429: 'Too Many Requests',
  431: 'Request Header Fields Too Large',
  451: 'Unavailable For Legal Reasons',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
  506: 'Variant Also Negotiates',
  507: 'Insufficient Storage',
  508: 'Loop Detected',
  510: 'Not Extended',
  511: 'Network Authentication Required',
};

export const hours = [...new Array(24).keys()].map((hour) => ({
  label: `${hour % 12 || 12}:00 ${hour >= 12 ? 'pm' : 'am'}`,
  value: `${`${hour}`.padStart(2, '0')}:00`,
}));

export const rowsPerPageOptions = [10, 25, 50];

export const catalogImagePlaceholder = `https://${config.trackingAppShortDomain}/static/pages/assets/placeholder.png`;

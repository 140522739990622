import React, { useState } from 'react';

import { IconButton } from '@material-ui/core';

import {
  FileCopyOutlined,
  VisibilityOutlined as VisibilityIcon,
  VisibilityOffOutlined as VisibilityOffIcon,
} from '@material-ui/icons';

import CopyToClipboardButton from './CopyToClipboardButton';
import TextField from './smb/fields/TextField';

const iconStyle = ({
  color: '#000',
  height: '16px',
  width: '16px',
});

export default function ReadOnlyField({
  initialState = false,
  placeholder,
  sensitive = false,
  value,
  ...rest
}) {
  const [showSecret, setShowSecret] = useState(initialState);
  const Eye = showSecret ? VisibilityIcon : VisibilityOffIcon;
  return (
    <TextField
      {...rest}
      className="ReadOnlyField-credentialInput"
      disabled
      value={value}
      type={(sensitive && !showSecret) ? 'password' : 'text'}
      placeholder={placeholder}
      InputProps={{
        ...rest.InputProps,
        endAdornment: (
          <>
            {sensitive && (
              <IconButton
                color="inherit"
                disabled={!value}
                onClick={() => setShowSecret(!showSecret)}
                size="small"
              >
                <Eye style={iconStyle} />
              </IconButton>
            )}
            <CopyToClipboardButton
              color="inherit"
              disabled={!value}
              Icon={FileCopyOutlined}
              iconStyle={iconStyle}
              source={[value]}
            />
          </>
        ),
      }}
      fullWidth
      readOnly
    />
  );
}

import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import {
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@material-ui/icons';

import { identity, isEmpty, kebabCase } from 'lodash';

import Grid from 'lib-frontend-shared/src/components/Grid';
import Linear from 'lib-frontend-shared/src/components/Linear';
import Typography from 'lib-frontend-shared/src/components/Typography';

import { useGlobalStates } from '../store';

import * as toastActions from '../actions/toast';

import Dialog from './Dialog';
import Menu from './Menu';
import Table from './Table';
import TableAddButton from './TableAddButton';
import { TextField } from './TextFields';

const prefix = (head, ...body) => kebabCase(
  [...(head ? [head] : []), ...body].join('-'),
);

const Action = ({
  disabled, entity, onDelete, setEditing, ...rest
}) => {
  const {
    route: {
      permissions: {
        canDelete = false,
        canEdit = false,
      } = {},
    },
  } = useGlobalStates(['route']);

  const options = [{
    Icon: EditIcon,
    key: prefix(entity, rest.name, 'update'),
    label: 'Edit',
    onClick: () => setEditing(rest),
    show: canEdit,
  }, {
    Icon: DeleteIcon,
    key: prefix(entity, rest.name, 'delete'),
    label: 'Delete',
    color: 'default',
    onClick: () => onDelete(rest),
    show: canDelete,
  }];

  return <Menu color="primary" disabled={disabled} options={options} />;
};

const getColumns = (entity, keyLabel, valLabel) => [{
  columnId: prefix(entity, keyLabel),
  label: keyLabel,
  width: '1fr',
  sortable: false,
  align: 'left',
  CellComponent: ({ row: { name = '' } = {} }) => name,
}, {
  columnId: prefix(entity, valLabel),
  label: valLabel,
  width: '1fr',
  sortable: false,
  align: 'center',
  CellComponent: ({ row: { val = '' } }) => val,
}, {
  columnId: prefix(entity, 'action'),
  label: 'Action',
  width: '70px',
  sortable: false,
  align: 'center',
  CellComponent: ({ row }) => <Action {...row} entity={entity} />,
}];

const KeyValDialog = ({
  entity,
  mode,
  onChange,
  onClose,
  sanitize,
  value,

  keyLabel,
  valLabel,
}) => {
  const [state, setState] = useState({});

  const open = Boolean(mode);

  useEffect(() => { if (open) setState(value); }, [open]);

  const modeText = mode === 'create' ? 'Add' : 'Update';

  // eslint-disable-next-line no-nested-ternary
  const tooltip = !state.name ? `Please provide ${keyLabel}` : (
    state.val ? undefined : `Please provide ${valLabel}`
  );

  const commons = (field) => ({
    onChange: ({ target }) => setState({
      ...state, [field]: sanitize(target.value, field),
    }),
    value: state[field] || '',
    variant: 'outlined',
  });

  return (
    <Dialog
      actions={[{
        disabled: Boolean(tooltip),
        onClick: () => onChange(state) && onClose(),
        tooltip,
        title: modeText,
      }]}
      size="lg"
      onClose={onClose}
      open={open}
      title={`${modeText} ${entity}`}
    >
      <Grid
        alignContent="center"
        alignItems="center"
        cols="repeat(2, auto)"
        flex
        gap="xl"
        height="100pr"
      >
        <Typography variant="h4">{keyLabel}</Typography>
        <TextField disabled={mode === 'update'} {...commons('name')} />
        <Typography variant="h4">{valLabel}</Typography>
        <TextField {...commons('val')} />
      </Grid>
    </Dialog>
  );
};

const keyExists = (value, key) => Object.keys(value).some(
  (entry) => entry.toLowerCase() === key.toLowerCase(),
);

const KeyValTable = ({
  disabled,
  entity,
  onChange: externalOnChange,
  keyLabel,
  sanitize = identity,
  valLabel,
  value = {},
  noDivider = false,
  ...props
}) => {
  const [editing, setEditing] = useState(undefined);

  // eslint-disable-next-line no-nested-ternary
  const mode = useMemo(() => (!editing ? undefined : (
    isEmpty(editing) ? 'create' : 'update'
  )), [editing]);

  const onChange = useCallback(({ name, val }) => {
    if (mode === 'create' && keyExists(value, name)) {
      toastActions.error(`${entity} already exists: ${name}`);
      return false;
    }
    externalOnChange({ ...value, [name]: val });
    return true;
  }, [externalOnChange, mode]);

  const onDelete = useCallback(({ name }) => {
    const { [name]: _, ...rest } = value;
    return externalOnChange(rest);
  }, [externalOnChange]);

  const rows = useMemo(
    () => Object.entries(value).map(([name, val]) => ({
      name, val, onDelete, setEditing,
    })),
    [value, onDelete],
  );

  const cols = useMemo(
    () => getColumns(entity, keyLabel, valLabel),
    [entity, keyLabel, valLabel],
  );

  return (
    <Linear width="100pr" orientation="vertical">
      <Linear
        align="center"
        justify="end"
        orientation="horizontal"
        width="100pr"
      >
        <TableAddButton disabled={disabled} onClick={() => setEditing({})}>
          Add Record
        </TableAddButton>
      </Linear>
      <Table
        columns={cols}
        rows={rows.map((row) => ({ ...row, disabled }))}
        noDivider={noDivider}
        {...props}
      />
      <KeyValDialog
        entity={entity}
        mode={mode}
        onChange={onChange}
        onClose={() => setEditing(undefined)}
        sanitize={sanitize}
        value={editing}

        keyLabel={keyLabel}
        valLabel={valLabel}
      />
    </Linear>
  );
};

export default KeyValTable;

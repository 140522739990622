export default {
  darkBlue: '#00063B',
  blue: '#90d8f9',
  cyan: '#80eadc',
  green: '#00ed96',
  grey: '#d8d8d8',
  lightGrey: '#cecece',
  lightRed: '#ed2626',
  orange: '#ffc541',
  red: '#ff0000',
  yellow: '#ffe180',
  primary: '#fb2661',
};

import React, {
  useCallback, useEffect, useMemo,
} from 'react';
import {
  flatMap,
  get,
  omit,
  pick,
  sortBy,
  uniq,
} from 'lodash';

import chain from 'lib-frontend-shared/src/helpers/chain';
import naturalSort from 'lib-frontend-shared/src/helpers/naturalSort';
import MaterialAutocomplete from './MaterialAutocomplete';


import useCities from '../helpers/useCities';

import { useGlobalStates } from '../store';

import { preloadGccCountries } from '../actions/global';

const pickCountries = (source, countries, showAllIfNoCountries = true) => chain(source)
  .fn((data) => ((countries.length === 0 && showAllIfNoCountries)
    ? data
    : pick(data, countries)
  ))
  .fn(Object.values)
  .fn(flatMap)
  .fn(uniq)
  .value;

const filterStates = (allStates, addressModelByCountry, countries = []) => {
  const selectiveStates = [];
  const networkByCountry = chain(countries)
    .fn('map', (country) => get(addressModelByCountry?.[country], 'network', []))
    .fn(flatMap)
    .value;
  if (networkByCountry?.filter(Boolean).length > 0) {
    networkByCountry.forEach(
      ({ state }) => selectiveStates.push(state),
    );
    const filteredStates = allStates.filter(({ value }) => selectiveStates.includes(value));
    return filteredStates;
  }
  return allStates;
};

const filterCities = (
  allCities = [],
  addressModelByCountry,
  countries = [],
  selectedStates = [],
) => {
  const showAllCities = selectedStates.length === 0;
  const selectiveCities = [];
  const networkByCountry = chain(countries)
    .fn('map', (country) => get(addressModelByCountry?.[country], 'network', []))
    .fn(flatMap)
    .value;
  if (networkByCountry?.filter(Boolean).length > 0) {
    if (!showAllCities) {
      const selectedStateCities = networkByCountry.filter(
        ({ state }) => selectedStates.includes(state),
      );
      selectedStateCities.forEach(({
        cityAreas = [],
      }) => cityAreas.forEach(({ city }) => selectiveCities.push(city)));
    } else {
      networkByCountry.forEach(({
        cityAreas = [],
      }) => cityAreas.forEach(({ city }) => selectiveCities.push(city)));
    }
    const filteredCities = allCities.filter(({ value }) => selectiveCities.includes(value));
    return filteredCities;
  }
  return allCities;
};

const CityStatePicker = ({
  allowFreeText = false,
  pickStates = false,
  statesFromFilter = false,
  countries = [],
  multiple,
  // if countries list is empty there are two ways we can decide to handle it
  // either give 0 cities/states. or give all cities/states of all countries loaded in mem.
  // default is to show all cities/states when country is present.
  showAllIfNoCountries = true,
  selectedStates = [],
  selectedCities = [],
  // other props
  ...pickerProps
}) => {
  // eslint-disable-next-line no-param-reassign
  countries = countries.filter(Boolean);
  const {
    global: { addressModelByCountry = {} },
  } = useGlobalStates(['global']);
  const {
    cities: citiesByCountry = {},
    states: statesByCountry = {},
    citiesByState = {},
  } = useCities(countries);

  const {
    value: pickerValue,
    onChange: onPickerValueChange,
  } = pickerProps;

  const cityToStateByCountry = useMemo(
    () => countries.reduce((countryAcc, country) => {
      // eslint-disable-next-line no-param-reassign
      countryAcc[country] = (
        citiesByState[country] || []
      ).reduce((acc, { cities, label, value }) => {
        cities.forEach(({ value: city }) => {
          // eslint-disable-next-line no-param-reassign
          acc[city] = { label, value };
        });
        return acc;
      }, {});
      return countryAcc;
    }, {}),
    [countries.sort(naturalSort).join(','), citiesByState],
  );

  const cityToState = useCallback((city) => {
    for (const country of countries) { // eslint-disable-line
      const match = cityToStateByCountry[country][city];
      if (match) return match;
    }
    return {};
  }, [cityToStateByCountry]);

  const source = pickStates ? statesByCountry : citiesByState;
  const options = useMemo(() => {
    let newOptions;
    if (pickStates) {
      newOptions = filterStates(sortBy(pickCountries(source, countries, showAllIfNoCountries), 'label'), addressModelByCountry, countries);
    } else if (selectedStates.length) {
      newOptions = chain(source)
        .fn((data) => (
          (countries.length === 0 && showAllIfNoCountries)
            ? data
            : pick(data, countries)
        ))
        .fn(Object.values)
        .fn(flatMap)
        .fn('filter', ({ value }) => selectedStates.includes(value))
        .fn(flatMap, 'cities')
        .fn(sortBy, 'label')
        .fn(filterCities, addressModelByCountry, countries, selectedStates)
        .fn(uniq)
        .value;
    } else {
      newOptions = filterCities(sortBy(
        pickCountries(omit(citiesByCountry, ['all']), countries, showAllIfNoCountries),
        'label',
      ), addressModelByCountry, countries, []);
    }
    return newOptions;
  }, [source, countries, selectedStates, citiesByCountry]);

  // pre-select state when city is selected
  useEffect(() => {
    if (!pickStates) return;

    let newValueOption;
    let newValue;

    if (!selectedCities?.length || statesFromFilter) {
      newValueOption = multiple ? [] : '';
      newValue = multiple ? [] : '';
    } else {
      // get the value for the picker
      newValueOption = multiple
        ? selectedCities.map(cityToState)
        : cityToState(selectedCities);

      // extract value to pass to the picker component
      newValue = multiple
        ? newValueOption.map(({ value }) => value)
        : get(newValueOption, 'value', '');
    }

    const autoSelected = true;
    const propagate = (
      (allowFreeText ? Boolean(newValue) : true)
      && selectedCities?.length
      && !statesFromFilter
    );

    // Propagate values upwards to the parent component state
    if (propagate) onPickerValueChange(newValue, autoSelected);
  }, [selectedCities, source]);


  // Preload GCC cities on mount if countries list is not specified.
  useEffect(() => {
    if (!countries?.length) {
      preloadGccCountries();
    }
  }, [countries]);

  return (
    <MaterialAutocomplete
      {...pickerProps}
      options={options}
      multiple={multiple}
      value={pickerValue}
      clearTextOnChange
    />
  );
};

export default CityStatePicker;

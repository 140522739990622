import React, { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';

import { Visibility, VisibilityOff } from '@material-ui/icons';

import TextField from './smb/fields/TextField';

const PasswordField = (props) => {
  const { label, ...rest } = props;
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const Icon = showPassword ? Visibility : VisibilityOff;
  return (
    <TextField
      {...rest}
      label={label}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <IconButton
            aria-label="toggle password visibility"
            onClick={toggleShowPassword}
            onMouseDown={toggleShowPassword}
            size="small"
          >
            <Icon />
          </IconButton>
        ),
      }}
    />
  );
};

export default PasswordField;

import { merge } from 'lodash';
import defaultConfig from './default.json';

const allConfigs = import.meta.glob('./*.json', { eager: true });
const envConfig = allConfigs[`./${import.meta.env.VITE_ENV}.json`].default;

/**
 * @type {{
 *   isProd?: true,
 *   googleMapApiKey: string,
 *   apiAuthDomain: string,
 *   auth0: {
 *     authDomain: string,
 *     clientId: string,
 *   },
 *   backendBaseUrl: string,
 *   middlewareBaseUrl: string,
 *   callbackBaseUrl: string,
 *   documentsBaseUrl: string,
 *   trackingAppBaseUrl: string,
 *   trackingAppShortDomain: string,
 *   customFontUrl: string,
 * }} */
const config = merge(defaultConfig, envConfig[window.location.host]);
window.carriyoEnvConfig = config;

// eslint-disable-next-line no-console
console.info(`%cEnv: ${import.meta.env.VITE_ENV}`, 'font-weight: bold;');

export default config;

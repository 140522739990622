import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';

import { find } from 'lodash';

import {
  IconButton, Popper,
} from '@material-ui/core';

import {
  ArrowDropDown, ArrowDropUp,
} from '@material-ui/icons';

import blockEvent from 'lib-frontend-shared/src/helpers/blockEvent';
import cls from 'lib-frontend-shared/src/helpers/cls';

import Chip from 'lib-frontend-shared/src/components/Chip';
import Linear from 'lib-frontend-shared/src/components/Linear';
import Spacer from 'lib-frontend-shared/src/components/Spacer';
import Typography from 'lib-frontend-shared/src/components/Typography';

import Dot from './Dot';

import './ChipSelect.scss';

const ChipSelect = ({
  clearable = false,
  children,
  className: classes,
  color = 'secondary',
  disabled,
  onChange,
  options = [],
  placeholder = 'Select a value',
  value,
  // eslint-disable-next-line no-shadow
  transform = ({ value }) => value,
  visible = ['ACTIVE'],
  ...rest
}) => {
  const [anchor, setAnchor] = useState(null);
  const [open, setOpen] = useState(false);

  const ref = useRef(null);

  const selected = find(options, { value }) || {};

  const toggleAnchor = () => setAnchor(anchor ? null : ref.current);

  const toggleOpen = (event) => {
    blockEvent(event);
    toggleAnchor();
    setOpen(!open);
  };

  const onSelect = (event) => {
    toggleOpen(event);
    onChange(event.target.value);
  };

  useEffect(() => {
    if (!open) return undefined;
    window.addEventListener('click', toggleOpen);
    return () => window.removeEventListener('click', toggleOpen);
  }, [open]);

  const Arrow = open ? ArrowDropUp : ArrowDropDown;

  const visibleOptions = useMemo(
    () => options.filter(({ status }) => visible.includes(status)),
    [options],
  );

  return (
    <Linear
      align="center"
      gap="sm"
      orientation="horizontal"
      {...rest}
    >
      {value ? (
        <Chip
          color={color}
          disabled={disabled}
          onClose={clearable && (() => onChange(undefined))}
          ref={ref}
          variant="filled"
        >
          <Typography nowrap size="xs">
            <Linear align="center" justify="end" orientation="horizontal">
              {children && (
                <>
                  {children}
                  <Spacer x="sm" />
                </>
              )}
              <div>{transform({ value: selected?.label || value })}</div>
              <Dot color={selected?.status} size="sm" />
            </Linear>
          </Typography>
        </Chip>
      ) : (
        <Typography nowrap ref={ref} variant="para.xs:body">
          <i>{placeholder}</i>
        </Typography>
      )}
      <Popper
        anchorEl={anchor}
        className="ChipSelect-popup"
        open={open}
      >
        <Typography className="ChipSelect-list" variant="para.xs">
          {/* eslint-disable-next-line no-shadow */}
          {visibleOptions.map(({ label, value }) => (
            <button
              className={cls('ChipSelect-listItem', {
                selected: value === selected?.value,
              })}
              key={label}
              onClick={onSelect}
              type="button"
              value={value}
            >
              {label}
            </button>
          ))}
        </Typography>
      </Popper>
      <IconButton
        color="secondary"
        disabled={disabled}
        onClick={toggleOpen}
        size="small"
      >
        <Arrow />
      </IconButton>
    </Linear>
  );
};

export default ChipSelect;

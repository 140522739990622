import React, { useEffect, useRef } from 'react';
import {
  every,
  includes,
  some,
} from 'lodash';
import { Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import cls from 'lib-frontend-shared/src/helpers/cls';
import Linear from 'lib-frontend-shared/src/components/Linear';
import Spacer from 'lib-frontend-shared/src/components/Spacer';
import moment from '../helpers/moment';
import useObjectState from '../helpers/useObjectState';
import getAllFocusableElements from '../helpers/getAllFocusableElements';
import { TextField } from './TextFields';
import './TimeField.scss';
import getTimezone from '../helpers/getTimezone';

const timers = {};
const clearDebounce = (key) => clearTimeout(timers[key]);
const debouncedCallFunc = (key, func, ...args) => {
  clearDebounce(key);
  timers[key] = setTimeout(() => func(...args), 100);
};

const error = {
  invalidValue: 'is an invalid value',
  partlyFilled: 'is partly filled',
  disablePastViolation: 'should not be a past date',
};

const blankTime = { hour: '', minute: '', period: '' };

const twentyFourFormat = ({ hour, period }) => moment(`${hour}:00 ${period}`, 'hh:mm a').format('HH');

const unpack = (packet, timezone) => {
  if (!packet) return blankTime;
  // do not sanitize - show packing issues
  const [hour = '', minute = '', period = ''] = moment(packet)
    .tz(timezone)
    .format('hh:mm:a')
    .split(':');
  return { hour, minute, period };
};

const fieldValidator = {
  hour: (hour) => /^(0?[1-9]|1[0-2])$/.test(hour),
  minute: (minute) => /^(0?|[1-5])[0-9]$/.test(minute),
  period: (period) => /^(p|a|am|pm)$/.test(period.toLowerCase()),
};

const isTimeValid = (time, trivial = false) => every(
  Object.entries(fieldValidator),
  ([field, predicate]) => {
    const val = time[field];
    return (trivial && val === '') || predicate(val);
  },
);

const isPartlyFilled = (time) => {
  const entries = Object.entries(time);
  return (
    some(entries, ([, val]) => val)
    && some(entries, ([, val]) => !val)
  );
};

const TimeField = (props) => {
  const {
    disabled = false,
    disablePast = false,
    className,
    timezoneOrCountry,
    heuristicOffset = 120,
    id = undefined,
    onChange = () => {},
    onFocus: externalOnFocus = () => {},
    onError = () => {},
    placeholder: customPlaceholder,
    range = { min: undefined, max: undefined },
    required = false,
    style = {},
    suppressPopup = false,
    syncInterval = 30,
    value = undefined,
    variant,
  } = props;
  const timezone = getTimezone(timezoneOrCountry);
  const pack = (time) => {
    const { hour, minute, period } = time;
    const base = value ? moment(value) : moment();
    base.set('hour', twentyFourFormat({ hour, period }));
    base.set('minute', minute);
    return base;
  };

  const doesObeyDisablePast = (time) => (!(disablePast && value)
    ? true
    : moment()
      .subtract(heuristicOffset, 'seconds')
      .isSameOrBefore(pack(time))
  );

  const stableIdRef = useRef(Math.trunc(Math.random() * 100000));
  const [time, setTime] = useObjectState(blankTime, true);

  const setTimeWithErrors = (timeSet) => {
    setTime(timeSet);
    if (!isTimeValid(timeSet, true)) {
      onError(error.invalidValue);
    } else if (isPartlyFilled(timeSet)) {
      onError(error.partlyFilled);
    } else if (!doesObeyDisablePast(timeSet)) {
      onError(error.disablePastViolation);
    } else {
      onError(undefined);
    }
  };

  useEffect(() => { setTimeWithErrors(unpack(value, timezone)); }, [value]);

  useEffect(() => {
    // poll every syncInterval after last update
    if (disablePast && syncInterval) {
      const ticker = setTimeout(
        () => setTimeWithErrors({ ...time }),
        syncInterval * 1000,
      );
      return () => clearTimeout(ticker);
    }
    return undefined;
  }, [time]);

  const inputRef = {
    hour: useRef(),
    minute: useRef(),
    period: useRef(),
  };

  const validateAndUpload = (timeSet) => {
    if (!isTimeValid(timeSet)) return;
    const base = pack(timeSet);
    const minDate = !range.min ? base : moment.max(
      base,
      moment(range.min),
    );
    const finalDate = !range.max ? minDate : moment.min(
      minDate,
      moment(range.max),
    );
    const packet = finalDate.toISOString();
    // no error testing here
    // do not set disablePast and min/max < now
    setTime(unpack(packet, timezone));
    onChange(packet);
  };

  const onInput = (field) => (event) => {
    const rawUpdate = event.target.value;
    const update = includes(['hour', 'minute'], field)
      ? rawUpdate.replace(/\D/g, '')
      : rawUpdate;

    const updatedTime = { ...time, [field]: update };

    setTimeWithErrors(updatedTime);

    const ready = {
      hour: (hour) => /^(01|0?[2-9]|1[0-2])$/.test(hour),
      minute: (minute) => /^(0?[6-9]|0[0-5]|[1-5][0-9])$/.test(minute),
      period: (period) => /^(a|p|am|pm)$/.test(period.toLowerCase()),
    }[field](update);

    if (ready) {
      const successor = {
        hour: 'minute',
        minute: 'period',
        period: undefined,
      }[field];
      if (successor) {
        const tabTarget = inputRef[successor].current;
        tabTarget.focus();
      } else {
        // Focusing successor will save things anyway (onBlur)
        // Else ensure updates are to user expectation
        validateAndUpload(updatedTime);
      }
    }
  };

  const onBlur = (field) => ({ target }) => {
    const update = target.value;
    const prettyUpdate = !fieldValidator[field](update) ? update : {
      hour: (hour) => moment(hour, 'hh').format('hh'),
      minute: (minute) => moment(minute, 'mm').format('mm'),
      period: (period) => period.toLowerCase(),
    }[field](update);

    const updatedTime = { ...time, [field]: prettyUpdate };
    setTimeWithErrors(updatedTime);
    validateAndUpload(updatedTime);
    // we dont know where this focus is going next. so wait a bit before defocusing
    // as the focus may return back to another text field within this component
    debouncedCallFunc(`${stableIdRef.current}-focus`, externalOnFocus, false);
  };

  const onFocus = (event) => {
    event.currentTarget.select();
    clearDebounce(`${stableIdRef.current}-focus`);
    externalOnFocus(true);
  };

  const onKeyDown = (event) => {
    const input = event.currentTarget;
    const isTextSelected = (input.selectionEnd !== input.selectionStart);
    if (
      event.key === 'ArrowRight'
      && input.selectionEnd === input.value.length
      && !isTextSelected
    ) {
      event.preventDefault();
      const inputs = getAllFocusableElements();
      const index = inputs.indexOf(input);
      if (index > -1) {
        const nextInput = inputs[(index + 1) % inputs.length];
        if (nextInput) {
          nextInput.focus();
          nextInput.selectionStart = 0;
          nextInput.selectionEnd = 0;
        }
      }
    } else if (
      (event.key === 'ArrowLeft' || event.key === 'Backspace')
      && input.selectionStart === 0
      && !isTextSelected
    ) {
      event.preventDefault();
      const inputs = getAllFocusableElements();
      const index = inputs.indexOf(input);
      if (index > -1) {
        const prevInput = inputs.slice(index - 1)[0];
        if (prevInput) {
          prevInput.focus();
          prevInput.selectionStart = prevInput.value.length;
          prevInput.selectionEnd = prevInput.value.length;
        }
      }
    }
  };

  const isNoInput = !(time.hour || time.minute || time.period);
  const obeysDisablePast = doesObeyDisablePast(time);
  const partlyFilled = isPartlyFilled(time);
  const allFilled = every(Object.entries(time), ([, val]) => val);

  const genTextField = (name, placeholder) => (
    <TextField
      id={id ? `${id}-${name}` : undefined}
      disabled={disabled}
      required={required}
      placeholder={(
        isNoInput && customPlaceholder
      ) ? customPlaceholder[name] : placeholder}
      style={{ width: variant === 'compact' ? '20px' : '25px' }}
      value={time[name]}
      error={Boolean(
        (partlyFilled && !time[name])
        || !obeysDisablePast
        || (time[name] && !fieldValidator[name](time[name])),
      )}
      inputRef={inputRef[name]}
      inputProps={{
        maxLength: 2,
        inputMode: 'numeric', // for mobile
        onInput: onInput(name),
        onBlur: onBlur(name),
        onFocus,
        onKeyDown,
      }}
    />
  );

  const showPopup = (
    !suppressPopup
    && allFilled
    && !obeysDisablePast
  );

  return (
    <Linear orientation="vertical" style={style}>
      <Linear className={cls('TimeField', { variant }, className)} orientation="horizontal">
        {genTextField('hour', 'hh')}
        <Typography variant="body1" className={cls('TimeField-separator', { symmetric: true })}>
          :
        </Typography>
        {genTextField('minute', 'mm')}
        <Typography variant="body1" className="TimeField-separator" />
        <Autocomplete
          id={id ? `${id}-period` : undefined}
          disabled={disabled}
          required={required}
          disableClearable
          freeSolo={false}
          style={{ width: '53px' }}
          options={[
            { label: 'am', value: 'am' },
            { label: 'pm', value: 'pm' },
          ]}
          onBlur={onBlur('period')}
          value={{ label: time.period }}
          onChange={(event, update) => onInput('period')({ target: update })}
          getOptionLabel={(option) => option.label}
          renderInput={(param) => (
            <TextField
              {...param}
              placeholder={(
                isNoInput && customPlaceholder
              ) ? customPlaceholder.period : ' am'}
              error={Boolean(
                (partlyFilled && !time.period)
                || !obeysDisablePast
                || (time.period && !fieldValidator.period(time.period)),
              )}
              inputRef={inputRef.period}
              inputProps={{
                ...param.inputProps,
                maxLength: 2,
                onFocus,
                onKeyDown,
              }}
            />
          )}
        />
      </Linear>
      {showPopup && (
        <div style={{ width: '110px' }}>
          <Spacer y="2xs" />
          <Typography variant="caption" color="error">
            Should not be a past date
          </Typography>
        </div>
      )}
    </Linear>
  );
};

export default TimeField;

import { omit, reduce } from 'lodash';
import qs from 'qs';
import toNumber from 'lib-frontend-shared/src/helpers/toNumber';
import getRelativeDate from './getRelativeDate';

const defaultParams = [
  'page',
  'rowsPerPage',
  'pageSize',
  'searchString',
  'sortBy',
  'sortDirection',
  'filterName',
];
const nonFilterParamsPerEntity = {
  reports: ['date'],
  shipments: ['shipmentType'],
};
export default (location = {}, entity = '') => {
  const {
    search = '',
  } = location;
  const nonFilterParams = [
    ...defaultParams,
    ...(nonFilterParamsPerEntity[entity] || []),
  ];
  const rawParams = qs.parse(search.replace(/^\?/, ''));
  const params = reduce(
    rawParams,
    (acc, val, key) => {
      if (nonFilterParams.includes(key)) {
        acc[key] = val;
        return acc;
      }
      const values = Array.isArray(val) ? val : [val];
      if (values.length) {
        acc[key] = values;
      }
      return acc;
    },
    {},
  );

  const filtersMap = omit(params, nonFilterParams);
  // special handling of date param
  if (params.date && params.date !== 'custom') {
    Object.assign(filtersMap, getRelativeDate(params.date));
  }
  const filters = Object.keys(filtersMap);
  return {
    nonFilterParams,
    params: {
      ...params,
      page: toNumber(params.page),
      pageSize: toNumber(params.pageSize),
    },
    filters,
    filtersMap,
  };
};

export const getUpdatedUrlState = (newState, oldState, removeEmptyString) => {
  const { nonFilterParams, params: oldParams } = oldState;

  const params = { ...oldParams, ...newState };

  const filters = Object.keys(omit(params, nonFilterParams));

  const filterParams = filters
    .filter((filterId) => params[filterId] !== undefined)
    .map((filterId) => {
      const filterValue = params[filterId];
      if (Array.isArray(filterValue)) {
        if (filterValue.length === 0) {
          return `${filterId}=[]`;
        }
        return filterValue
          .map((value) => `${filterId}=${encodeURIComponent(value)}`)
          .join('&');
      }
      return `${filterId}=${encodeURIComponent(filterValue)}`;
    })
    .join('&');

  return nonFilterParams
    .filter((key) => ![...(removeEmptyString ? [''] : []), undefined].includes(params[key]))
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .concat(filterParams ? [filterParams] : [])
    .join('&');
};

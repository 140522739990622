import React from 'react';

import {
  Tooltip as MuiTooltip,
  withStyles,
} from '@material-ui/core';

import cls from 'lib-frontend-shared/src/helpers/cls';

import './Dot.scss';
import palette from '../helpers/colorPalette';

const Tooltip = withStyles(() => ({
  tooltip: {
    padding: 0,
    width: 0,
    left: '20px',
    position: 'absolute',
  },
}))(MuiTooltip);

const colorMap = {
  OK: palette.green,
  ACTIVE: palette.green,
  DELETED: palette.red,
  INACTIVE: palette.grey,
  TROUBLE: palette.red,
  UNSUPPORTED: palette.orange,
  PRIMARY: palette.primary,

  // Shipment Statuses

  BOOKED: palette.lightGrey,
  READY_TO_SHIP: palette.lightGrey,
  AWAITING_CUSTOMER_COLLECTION: palette.lightGrey,
  CANCELLED_BY_CARRIER: palette.lightGrey,
  PENDING: palette.lightGrey,
  DRAFT: palette.lightGrey,

  ERROR: palette.lightRed,
  MISSING: palette.lightRed,
  DELAYED: palette.lightRed,
  SUSPENDED: palette.lightRed,

  SHIPPED: palette.blue,
  IN_TRANSIT: palette.blue,
  OUT_FOR_DELIVERY: palette.blue,

  DELIVERED: palette.cyan,
  DELIVERY_CONFIRMED: palette.cyan,

  CANCELLED: palette.yellow,
  FAILED_DELIVERY_ATTEMPT: palette.yellow,
  FAILED_COLLECTION_ATTEMPT: palette.yellow,
  READY_FOR_RETURN: palette.yellow,
  RETURN_IN_TRANSIT: palette.yellow,
  RETURNED: palette.yellow,
  RETURN_CONFIRMED: palette.yellow,
};

const parseColor = (color = '') => (
  colorMap[color.toUpperCase()]
  || color.toUpperCase()
  || colorMap.DELETED
);

const Dot = ({
  color, size, noPadding = false, tooltip = null,
}) => {
  const dot = (
    <div
      className={cls('Dot-dot', { size })}
      style={{ background: parseColor(color) }}
    />
  );

  const tooltipBody = (
    <div className="Dot-tooltip">
      {tooltip}
    </div>
  );

  return (
    <div className={cls('Dot', { size, noPadding })}>
      {tooltip ? (
        <Tooltip title={tooltipBody}>{dot}</Tooltip>
      ) : (
        dot
      )}
    </div>
  );
};

export default Dot;

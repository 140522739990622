/* eslint-disable no-nested-ternary */
import {
  castArray,
  isEqual,
  omit,
  uniq,
  xorWith,
} from 'lodash';
import moment from '../helpers/moment';
import { Status } from '../enums';
import {
  getStates, history, updateCachedEntities, updateCarrier,
} from '../store';
import { push, replace } from './route';
import * as apiClient from '../api-client';
import * as toastActions from './toast';
import { changelogFetcher } from './changelog';
import forceRedirect from '../helpers/forceRedirect';
import { reader, writer } from '../helpers/action';
import trim from '../helpers/trim';
import { fetchUserTenantPref, saveUserTenantPref } from '../helpers/userPrefSessionStorage';
import getCarrierListStatesFromURL from '../helpers/getStatesFromURL';
import { isClicknshipCarrierAccount } from './clicknship-service';
import { fetchNonExistingIds, upsertEntity } from './cached-entities';

export const setState = updateCarrier;
const sessionStorageKey = 'carrierList-v1';

const singularEntity = 'carrier';
const pluralEntities = `${singularEntity}s`;

const openStatusesWithCarrier = [
  'booked',
  'ready_to_ship',
  'shipped',
  'in_transit',
  'out_for_delivery',
  'failed_collection_attempt',
  'failed_delivery_attempt',
  'awaiting_customer_collection',
  'ready_for_return',
  'return_in_transit',
  'delayed',
  'suspended',
  // exclude pre-booking statuses
  // 'draft',
  // 'cancelled',
  // 'cancelled_by_carrier',
  // 'pending',
  // 'error',
  // exclude "end statuses"
  // 'missing',
  // 'delivered',
  // 'returned',
];

export const restoreLastCarrierListState = () => {
  const { auth: { tenantId }, global: { selectedMerchantIds = [] } } = getStates();
  const {
    queryString = '',
    selectedMerchantIds: lastSavedMerchantIds = [],
  } = fetchUserTenantPref(sessionStorageKey) || {};
  const hasChanged = xorWith(selectedMerchantIds, lastSavedMerchantIds, isEqual).length > 0;
  const params = new URLSearchParams(queryString);
  if (hasChanged) params.delete('page');
  const query = params.toString();
  replace(`/tenants/${tenantId}/carriers${query ? `?${query}` : ''}`, false);
};


export const getCarriers = reader(
  pluralEntities,
  async (filters, signal) => {
    const { global: { selectedMerchantIds, merchantIds } } = getStates();
    const { location } = history;
    const { status } = filters;
    const {
      params: {
        page = 0,
        pageSize = 10,
        searchString,
        sortBy = 'carrier_account_name',
        sortDirection = 'ASC',
      } = {},
      filtersMap = {},
    } = getCarrierListStatesFromURL(location, 'carriers');
    const allMerchants = merchantIds.length === selectedMerchantIds.length;
    const result = await apiClient.getCarrierList({
      page,
      pageSize,
      searchString,
      status,
      allFilters: filtersMap,
      sortBy,
      sortDirection,
      allMerchants,
    }, signal);
    setState(result);
    saveUserTenantPref(sessionStorageKey, {
      ...(fetchUserTenantPref(sessionStorageKey) || {}),
      queryString: location.search,
      selectedMerchantIds,
    });
    return result;
  },
);


export const getCarrierOptions = reader(
  pluralEntities,
  async ({
    searchString,
    allFilters,
  } = {}) => {
    const { carriers = [] } = await apiClient.getCarrierList({
      page: 0,
      pageSize: 100,
      searchString,
      status: 'ACTIVE',
      sortBy: 'carrier_account_name',
      sortDirection: 'asc',
      allFilters,
      fields: [],
      excludeClicknshipChildAccounts: false,
      statistics: false,
    });
    upsertEntity({
      entity: pluralEntities,
      list: carriers,
      identifier: 'carrierId',
    });
    return carriers;
  },
);


export const getAllCarrierCount = reader(
  pluralEntities,
  async () => {
    const { totalCarriers } = await apiClient.getCarrierList({
      allMerchants: true,
      page: 0,
      pageSize: 0,
    });

    setState({
      totalCarriersCount: totalCarriers,
    });
    return totalCarriers;
  },
);

export const getCarrierDetails = reader(
  pluralEntities,
  async ({
    carrierIds = [],
    accountNames = [],
    carrierNames = [],
    allMerchants = true,
  } = {}) => {
    const { cachedEntities: { carriers: existingCarriers = [] } = {} } = getStates();
    if (!carrierIds.length && !accountNames.length && !carrierNames.length) return { carriers: existingCarriers };
    const castedCarrierIds = uniq(castArray(carrierIds).filter(Boolean));
    const castedCarrierNames = uniq(castArray(carrierNames).filter(Boolean));
    const castedAccountNames = uniq(castArray(accountNames).filter(Boolean));

    if (castedCarrierIds.length < 1 && castedAccountNames.length < 1 && castedCarrierNames.length < 1) {
      return { carriers: existingCarriers };
    }
    const identifier = castedCarrierIds.length ? 'carrierId' : (castedCarrierNames.length ? 'name' : 'accountName');
    const nonExistingIdentifiers = fetchNonExistingIds({
      identifiers: castedCarrierIds.length
        ? castedCarrierIds
        : (castedCarrierNames.length ? castedCarrierNames : castedAccountNames),
      entity: pluralEntities,
      identifier,
    });
    if (nonExistingIdentifiers.length > 0) {
      const { global: { selectedMerchantIds, merchantIds } } = getStates();
      // eslint-disable-next-line no-param-reassign
      allMerchants = allMerchants || merchantIds.length === selectedMerchantIds.length;
      const queryIdentifier = castedCarrierIds.length ? 'carrierIds' : (castedCarrierNames.length ? 'carrierNames' : 'accountNames');
      const { carriers = [] } = await apiClient.getCarrierDetails({
        [queryIdentifier]: nonExistingIdentifiers,
        allMerchants,
      });
      const { carriers: updatedCarriers } = upsertEntity({
        entity: pluralEntities,
        list: carriers.filter(({ carrierId }) => Boolean(carrierId)),
        identifier: 'carrierId',
      });
      return { carriers: updatedCarriers };
    }
    const { cachedEntities: { carriers = [] } = {} } = getStates();
    return { carriers };
  },
);


export const getCarrierById = reader(
  singularEntity,
  async (carrierId) => {
    const data = await apiClient.getCarrierById(carrierId);
    if (isClicknshipCarrierAccount(data)) {
      throw new Error('Carrier Account not found by provided id');
    }
    return data;
  },
  {
    onFailure: (message) => {
      toastActions.error(message);
      push('/carriers', false);
    },
  },
);

export const getTotalActiveCarriers = reader(
  pluralEntities,
  async (signal) => {
    const {
      totalCarriers = 0,
    } = await apiClient.getCarrierList({
      status: Status.active,
      excludeCarrier: 'TEST',
      allMerchants: true,
      page: 0,
      pageSize: 0,
    }, signal);
    setState({
      totalActiveCarriers: totalCarriers,
    });
    return totalCarriers;
  },
);

export const getAdditionalCarrierStatistics = reader(
  pluralEntities,
  async () => {
    // find open shipments in last 48 hours and any errors shipments (any time)
    const time48HoursAgo = moment().subtract(48, 'hours');
    const [
      { aggBy: erroredShipmentsByCarrierId },
      { aggBy: erroredReverseShipmentsByCarrierId },
      // "open" means shipment that were successfully booked but not reached an end-status
      { aggBy: openShipmentsBefore48HoursByCarrierId },
      { aggBy: openReverseShipmentsBefore48HoursByCarrierId },
      // for idle calculation
      { aggBy: openShipmentsByCarrierId },
      { aggBy: openReverseShipmentsByCarrierId },
    ] = await Promise.all([
      // error shipments
      apiClient.getShipments({
        allMerchants: true,
        page: 0,
        pageSize: 0,
        allFilters: {
          status: ['error'],
          // only count shipments in error due to carriers issues
          error_source: 'CARRIER',
        },
        aggBy: 'carrier_id',
      }),
      apiClient.getReverseShipments({
        allMerchants: true,
        page: 0,
        pageSize: 0,
        allFilters: {
          status: ['error'],
          // only count shipments in error due to carriers issues
          error_source: 'CARRIER',
        },
        aggBy: 'carrier_id',
      }),
      // open shipments before 48h
      apiClient.getShipments({
        allMerchants: true,
        page: 0,
        pageSize: 0,
        allFilters: {
          status: openStatusesWithCarrier,
          booked_milestone_date_to: time48HoursAgo.toISOString(),
        },
        aggBy: 'carrier_id',
      }),
      // open reverse shipments before 48h
      apiClient.getReverseShipments({
        allMerchants: true,
        page: 0,
        pageSize: 0,
        allFilters: {
          status: openStatusesWithCarrier,
          booked_milestone_date_to: time48HoursAgo.toISOString(),
        },
        aggBy: 'carrier_id',
      }),
      // all open shipments (regardless of time)
      apiClient.getShipments({
        allMerchants: true,
        page: 0,
        pageSize: 0,
        allFilters: {
          status: openStatusesWithCarrier,
        },
        aggBy: 'carrier_id',
      }),
      apiClient.getReverseShipments({
        allMerchants: true,
        page: 0,
        pageSize: 0,
        allFilters: {
          status: openStatusesWithCarrier,
        },
        aggBy: 'carrier_id',
      }),
    ]);
    setState({
      erroredShipmentsByCarrierId: Object.keys({
        ...erroredShipmentsByCarrierId,
        ...erroredReverseShipmentsByCarrierId,
      }).reduce((acc, key) => {
        acc[key] = (erroredShipmentsByCarrierId[key] || 0)
          + (erroredReverseShipmentsByCarrierId[key] || 0);
        return acc;
      }, {}),
      openShipmentsBefore48HoursByCarrierId: Object.keys({
        ...openShipmentsBefore48HoursByCarrierId,
        ...openReverseShipmentsBefore48HoursByCarrierId,
      }).reduce((acc, key) => {
        acc[key] = (openShipmentsBefore48HoursByCarrierId[key] || 0)
          + (openReverseShipmentsBefore48HoursByCarrierId[key] || 0);
        return acc;
      }, {}),
      time48HoursAgo,
      openShipmentsByCarrierId: Object.keys({
        ...openShipmentsByCarrierId,
        ...openReverseShipmentsByCarrierId,
      }).reduce((acc, key) => {
        acc[key] = (openShipmentsByCarrierId[key] || 0)
          + (openReverseShipmentsByCarrierId[key] || 0);
        return acc;
      }, {}),
    });
  },
);

export const getCarrierMetadata = reader(
  `${singularEntity} metadata`,
  async () => {
    const { data } = await apiClient.getCarrierMetadata();
    return data;
  },
);

export const getCarrierHistory = changelogFetcher(singularEntity, 'CarrierAccount');

export const deleteCarrier = writer(
  singularEntity,
  async (carrierId) => {
    const { cachedEntities, cachedEntities: { carriers } } = getStates();
    await apiClient.deleteCarrier({ carrierId });
    // We need to make our cached carrier list aligned with the updated carriers
    updateCachedEntities({
      ...cachedEntities,
      carriers: carriers.filter(({ carrierId: id }) => id !== carrierId),
    });
    restoreLastCarrierListState();
    getCarriers();
  },
  { operation: 'delete' },
);

export const upsertCarrierSetting = writer(
  singularEntity,
  async (payload, allowRedirect = true, cloneModeRedirectingPath) => {
    const { cachedEntities, cachedEntities: { carriers } } = getStates();
    const { carrierId } = payload;
    const { data } = await apiClient.upsertCarrierSetting(trim(payload));
    const { carrierId: newCarrierId } = data;
    // We need to make our cached carrier list aligned with the updated carriers
    const index = carriers.findIndex(({ carrierId: id }) => id === newCarrierId);
    if (index > -1) {
      carriers[index] = data;
      updateCachedEntities({
        ...cachedEntities,
        carriers,
      });
    }
    getCarrierHistory(carrierId || newCarrierId);
    if (!carrierId && allowRedirect) {
      forceRedirect(`/carriers/edit/${newCarrierId}/account-setup`, false, cloneModeRedirectingPath);
    }
    return data;
  },
);

export const getCitiesMapping = reader(
  `${singularEntity} city mappings`,
  async (...args) => {
    const { data } = await apiClient.getCarrierCitiesMapping(...args);
    return data;
  },
);

export const getStatusMapping = reader(
  `${singularEntity} status mappings`,
  async (...args) => {
    const { data } = await apiClient.getCarrierStatusMapping(...args);
    return data;
  },
);

export const generateAccountRequest = writer(
  `${singularEntity} request`,
  async (payload) => {
    const { data } = await apiClient.generateAccountRequest(payload);
    return data;
  },
  {
    operation: 'send',
  },
);

export const setURLState = (payload) => {
  const { auth: { tenantId } } = getStates();
  const { location } = history;

  const {
    nonFilterParams,
    params: oldParams,
  } = getCarrierListStatesFromURL(location, 'carriers');
  const params = {
    ...oldParams,
    ...payload,
  };

  const filters = Object.keys(omit(params, nonFilterParams));

  const filterParams = filters
    .filter((filterId) => params[filterId] !== undefined)
    .map((filterId) => {
      const filterValue = params[filterId];
      if (Array.isArray(filterValue)) {
        if (filterValue.length === 0) {
          return `${filterId}=[]`;
        }
        return filterValue
          .map((value) => `${filterId}=${encodeURIComponent(value)}`)
          .join('&');
      }
      return `${filterId}=${encodeURIComponent(filterValue)}`;
    })
    .join('&');

  const queryParams = nonFilterParams
    .filter((key) => params[key] !== undefined)
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .concat(filterParams ? [filterParams] : [])
    .join('&');
  // set url
  push(`/tenants/${tenantId}/carriers?${queryParams}`, false);
};

export const getUserPref = () => {
  // get from sessionStorage
  const userPref = fetchUserTenantPref(sessionStorageKey) || {};
  updateCarrier({ userPref });
};

export const assignUserPref = (partialPref = {}, shouldUpdateState = true) => {
  const oldUserPref = fetchUserTenantPref(sessionStorageKey) || {};
  const userPref = {
    ...oldUserPref,
    ...partialPref,
  };
  // save to sessionStorage
  saveUserTenantPref(sessionStorageKey, userPref);
  if (shouldUpdateState) {
    updateCarrier({ userPref });
  }
};

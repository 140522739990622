import React from 'react';
import { range } from 'lodash';

import { IconButton } from '@material-ui/core';

import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons';

import pluralize from 'lib-frontend-shared/src/helpers/pluralize';

import './QuantitySelect.scss';

import MaterialAutocomplete from './MaterialAutocomplete';
import {
  AutocompleteTextField,
} from './TextFields';

const Button = (props) => {
  const { Icon, ...rest } = props;
  return (
    <IconButton {...rest} size="small">
      <Icon />
    </IconButton>
  );
};

const defaultLabelTransform = ({ unit, value }) => [
  value, unit && pluralize(unit, value),
].filter((val) => ![null, undefined].includes(val)).join(' ');

const QuantitySelect = (props) => {
  const {
    disabled,
    labelTransform = defaultLabelTransform,
    min = 0,
    max = 100,
    onChange = () => {},
    step = 10,
    range: optionRange = range(min, max + step, step),
    unit,
    value,
    zeroText,
    ...rest
  } = props;

  const options = optionRange.map((value) => ({ // eslint-disable-line no-shadow
    label: ![undefined, null, ''].includes(zeroText) && zeroText && value === 0
      ? zeroText
      : labelTransform({ unit, value }),
    value,
  }));

  return (
    <div className="QuantitySelect">
      <MaterialAutocomplete
        clearable={false}
        width="long"
        {...rest}
        disabled={disabled}
        freeSolo={false}
        InputComponent={AutocompleteTextField}
        onChange={onChange}
        options={options}
        popupIcon={null}
        value={value}
      />
      <div className="QuantitySelect-arrows">
        <Button
          disabled={disabled}
          Icon={ArrowDropUp}
          onClick={() => {
            const next = options.findIndex((option) => option.value === value);
            return onChange(options[next + 1]?.value || options.at(-1).value);
          }}
        />
        <Button
          disabled={disabled}
          Icon={ArrowDropDown}
          onClick={() => {
            const next = options.findIndex((option) => option.value === value);
            return onChange(options[next - 1]?.value || options.at(0).value);
          }}
        />
      </div>
    </div>
  );
};

export default QuantitySelect;

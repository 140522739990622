import React from 'react';

import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@material-ui/icons';

import Linear from 'lib-frontend-shared/src/components/Linear';
import Typography from 'lib-frontend-shared/src/components/Typography';

import MaterialAutocomplete from './MaterialAutocomplete';

import './MerchantSelect.scss';

const preventSubmission = (event) => {
  if (event.key === 'Enter') {
    event.preventDefault();
  }
};

const Access = {
  all: 'all-merchants',
  selection: 'select-merchants',
};

const MerchantSelect = ({
  canAccessAllMerchants = true,
  disabled,
  merchants = null,
  onChange,
  value,
}) => {
  const access = value ? Access.selection : Access.all;
  return (
    <RadioGroup
      name="merchant"
      value={access}
      /* eslint-disable-next-line no-shadow */
      onChange={(event, value) => onChange(value === Access.all ? null : [])}
      onKeyDown={preventSubmission}
    >
      <FormControlLabel
        value={Access.all}
        control={<Radio />}
        disabled={disabled || !canAccessAllMerchants}
        label={(
          <Typography
            color={access === Access.all ? 'normal' : 'disabled'}
            variant="para.sm"
          >
            All Merchants (including any merchant added in future)
          </Typography>
        )}
      />
      <Linear gap="lg" orientation="vertical">
        <FormControlLabel
          value={Access.selection}
          control={<Radio />}
          disabled={disabled}
          label={(
            <Typography
              color={access === Access.selection ? 'normal' : 'disabled'}
              variant="para.sm"
            >
              Select Merchants
            </Typography>
          )}
        />
        <Linear orientation="vertical" width="75Pr" gap="sm">
          <Typography variant="para.xs:body">
            Allowed Merchants
          </Typography>
          <MaterialAutocomplete
            disabled={disabled || access !== Access.selection}
            clearable={false}
            multiple
            freeSolo={false}
            isNewDesign
            options={merchants.map(({ id, name }) => ({ label: name, value: id }))}
            popupIcon={<KeyboardArrowDownIcon className="Location-popupIcon" />}
            onChange={(update) => onChange(update.map(({ value: id }) => id))}
            value={value || []}
          />

        </Linear>
        {value?.length === 0 && (
          <Typography color="error" variant="para.xs">
            Please select at least one merchant
          </Typography>
        )}
      </Linear>
    </RadioGroup>
  );
};

export default MerchantSelect;

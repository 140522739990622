import {
  nodeMiddlewareClient,

  // utils
  timeZoneOffset,
  toMerchantsParam,
  userAccessRestrictor,

} from './base';
import { getStates } from '../store';

export const getAlerts = async ({
  page,
  pageSize,
  allFilters = {},
  sortBy,
  sortDirection,
  aggBy,
  allMerchants = false,
}, signal) => {
  const {
    global: { selectedMerchantIds },
  } = getStates();
  const { data } = await nodeMiddlewareClient.get('/alerts', {
    signal,
    params: userAccessRestrictor({
      filters: {
        page,
        pageSize,
        ...toMerchantsParam(allMerchants ? undefined : selectedMerchantIds),
        ...(sortBy ? { sortBy } : {}),
        ...((sortBy && sortDirection) ? { sortDirection: sortDirection.toUpperCase() } : {}),
        ...allFilters,
        ...(aggBy ? { aggBy } : {}),
        tzoffset: timeZoneOffset,
      },
      fallbackLocationField: 'pickup_location',
      fallbackCountryField: 'pickup_country',
    }),
  });
  const {
    pagination: {
      page: pageNum,
      totalRows,
    },
    alerts,
    aggBy: aggByResult,
  } = data;

  return {
    page: Math.max(0, pageNum),
    totalRows,
    alerts,
    aggBy: aggByResult,
  };
};


export const updateAllAlertsStatus = async ({
  page,
  pageSize,
  allFilters = {},
  sortBy,
  sortDirection,
  aggBy,
  allMerchants = false,
}) => {
  const {
    global: { selectedMerchantIds },
  } = getStates();
  await nodeMiddlewareClient.post('/alerts/bulk-dismiss', undefined, {
    params: userAccessRestrictor({
      filters: {
        page,
        pageSize,
        ...toMerchantsParam(allMerchants ? undefined : selectedMerchantIds),
        ...(sortBy ? { sortBy } : {}),
        ...((sortBy && sortDirection) ? { sortDirection: sortDirection.toUpperCase() } : {}),
        ...allFilters,
        ...(aggBy ? { aggBy } : {}),
        tzoffset: timeZoneOffset,
      },
      fallbackLocationField: 'pickup_location',
      fallbackCountryField: 'pickup_country',
    }),
  });
  return {};
};

export const updateAlertStatus = async (body) => nodeMiddlewareClient.post('/alerts/status', body);

export const getUserAlertSettings = async () => {
  const { data } = await nodeMiddlewareClient.get(
    '/alerts/user-subscription',
  );
  return data;
};

export const updateUserAlertSettings = async (body) => nodeMiddlewareClient.patch('/alerts/user-subscription', body);

/* eslint-disable react/jsx-no-comment-textnodes */

import React from 'react';

import Linear from 'lib-frontend-shared/src/components/Linear';
import Typography from 'lib-frontend-shared/src/components/Typography';

import TextField from './smb/fields/TextField';

const nonPermittedCharRegex = /[0-9`~!@#$%^&*()+\-_=[{\]}\\|'<,.>?/";:£§º©®\s]/g;
const validSeparatorRegex = /[.｡。･・︒។։။۔።।]/;

const What3WordsInput = ({
  onChange,
  required: extRequired,
  style,
  value = '',
  ...rest
}) => {
  const valueParts = value?.replace(/^\/+:?/g, '')?.split(validSeparatorRegex) || [];
  const words = [...new Array(3)].map((_, index) => valueParts[index]?.trim() || '');
  const required = extRequired || words.some(Boolean);
  return (
    <Linear align="center" gap="sm" orientation="horizontal" style={style} width="100pr">
      {words.map((word, index) => (
        <>
          <TextField
            {...rest}
            onChange={(event) => {
              const updatedParts = words.map((part, partIndex) => (
                partIndex === index ? event.target.value.replace(nonPermittedCharRegex, '') : part
              ));
              return onChange(updatedParts.filter(Boolean).length ? `///${updatedParts.join('.')}` : '');
            }}
            required={required}
            style={{ width: '100px' }}
            value={word}
          />
          {index !== words.length - 1 && (
            <Typography variant="para.sm">
              //
            </Typography>
          )}
        </>
      ))}
    </Linear>
  );
};

export default What3WordsInput;

import React from 'react';
import cls from '../helpers/cls';
import './Grid.css';

const Grid = ({
  children,
  className: classes,

  alignContent,
  alignItems,
  justifyContent,
  justifyItems,

  flex,
  gap,
  cols,
  rows,
  colGap = gap,
  rowGap = gap,

  height,
  width,

  style = {},
  ...rest
}) => {
  const className = cls('Grid', {
    alignContent,
    alignItems,
    justifyContent,
    justifyItems,

    flex,
    colGap,
    rowGap,

    height,
    width,
  }, classes);

  return (
    <div
      className={className}
      style={{
        ...style,
        gridTemplateColumns: cols,
        gridTemplateRows: rows,
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Grid;
